import SERVICE_DATA from "../data/service.data";

const AUDIT_PLAN_DATA = [];

let randomAudtoLoop = Math.floor(Math.random() * 10) + 4;

for (let i = 1; i < randomAudtoLoop; i++) {
    let services_default = [].concat(SERVICE_DATA);
    let services = [];

    let randomLoop = Math.floor(Math.random() * 10) + 4;

    for (let i = 1; i < randomLoop; i++) {
        let randomServiceIndex = Math.floor(Math.random()*services_default.length);
        let randomServiceItem = services_default[randomServiceIndex];

        services.push(randomServiceItem);
        services_default.splice(randomServiceIndex, 1);
    }

    AUDIT_PLAN_DATA.push({
        id: i,
        name: getAudtiName(),
        services: services
    })
}

function getAudtiName() {
    let names = [
        'Prüfplan Steinfrüchte',
        'Mein Prüfplan',
        'Äpfel Prüfplan',
        'Mais Prüfplan',
        'Pflaumen Prüfplan',
        'Meine Favouriten',
        'Galab Spezial',
        'Nüsse',
        'Quecksilber #1',
        'Quecksilber #2',
        'Quecksilber #3',
    ];

    return names[Math.floor(Math.random() * names.length)];
}

export default AUDIT_PLAN_DATA;
