const COMPANY_DATA = [];

for (let i = 1; i < 25; i++) {
    COMPANY_DATA.push({
        id: i,
        company_name: getCompanyName(),
        company_id: getRandomNumber(),
        users: generateUser(),
        services: [],
        audit_plans: [],
        last_order: randomDate(new Date(2012, 0, 1), new Date()).toISOString().slice(0,10),
        created: randomDate(new Date(2012, 0, 1), new Date()).toISOString().slice(0,10)
    });
}

function getCompanyName() {
    let names = [
        'Google',
        'Apple',
        'Bosch',
        'Musterfirma',
        'Galab',
        'Concore',
        'Huawei',
        'Starbucks',
        'McDonalds',
        'BioCompany',
    ];

    return names[Math.floor(Math.random() * names.length)];
}

function generateUser() {
    let returnUser = [];
    let randomUserLoop = Math.floor(Math.random() * 10) + 2;

    for (let i = 1; i < randomUserLoop; i++) {
        returnUser.push({
            first_name: getFirstName(),
            last_name: getLastName(),
            last_login: randomDate(new Date(2012, 0, 1), new Date()).toISOString().slice(0,10),
        });
    }

    return returnUser;
}

function getFirstName() {
    let names = [
        'Helge',
        'Helga',
        'Max',
        'Anton',
        'Stefanie',
        'Anna-Lena',
        'Karl',
        'Klaus',
        'Rosa',
        'Gaby',
        'Melanie',
        'Tanja',
    ];

    return names[Math.floor(Math.random() * names.length)];
}

function getLastName() {
    let names = [
        'Müller',
        'Meier',
        'Huber',
        'Schubert',
        'Dotterweich',
        'Michaelis',
        'Dietrich',
        'Schuster',
        'Metz',
        'Carl',
    ];

    return names[Math.floor(Math.random() * names.length)];
}

function getRandomNumber() {
    return Math.floor(Math.random() * 1000000) + 1000000;
}

function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export default COMPANY_DATA;
