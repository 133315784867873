import axios from "axios/index";
import {getToken,removeUserSession} from "../utils/Common";

export const GALAB_API_URL = 'https://galab.api.staging-env.de/';
export const GALAB_API_VERSION = 'api/';

const galabApi = axios.create({
    baseURL: GALAB_API_URL + GALAB_API_VERSION
});

galabApi.defaults.headers.common['Authorization'] = "Bearer " + getToken();

galabApi.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            removeUserSession();

            window.location.reload();
        } else {
            throw error;
        }
    }
);

export default galabApi;