import React from 'react';

import Message from './Message';

const MessageStream = () => (
    <div>
        <div className="message-stream-timestamp"><span>27. März</span></div>
        <Message type="message message-user" text="Falls Sie Fragen zu Ihrem Auftrag oder zu Ihrer Probe haben, können Sie diese in diesem Bereich stellen." />
        <Message type="message message-self" text="Ich habe ein Problem" />
    </div>
);

export default MessageStream;