import React from 'react';

import OrderMetaData from "./OrderMetaData";
import ServiceList from '../components/ServiceList';
import Status from '../components/Status';
import {getLabelByName} from "../utils/Forms";
import Moment from "moment/moment";
import Tooltip from './Tooltip';

const OrderDetailMetaData = ({ order, editHandle }) => (
    <div className="use-space scrollable">
        <div className="content-inner">
            <dl>
                <dt>{getLabelByName('articleNumber')}</dt>
                <dd>{order.articleNumber}</dd>
                <dt>Atkueller Status</dt>
                <dd><Status state={order.state} /></dd>
                <dt>Erstellungsdatum</dt>
                <dd>
                    <Tooltip content={Moment(order.createdAt).format('H:mm') + ' Uhr'}>
                        {Moment(order.createdAt).format('DD.MM.YYYY')}
                    </Tooltip>
                </dd>
                <dt>Erstellt von</dt>
                <dd>{order.author}</dd>
                <dt>Vorauss. Lieferdatum</dt>
                <dd>{Moment(order.deliveryDate).format('DD.MM.YYYY')}</dd>
            </dl>
            {(order.image !== '') ?
                <img src={order.image} />
            : null}
        </div>
        <div className="border-top">
            <div className="content-inner">
                <OrderMetaData order={order} />
            </div>
            {(order.analysisServiceData.length > 0) ?
                <ServiceList
                    items={order.analysisServiceData}
                    selectedItems={[]}
                />
                :
                <div className="content-inner collapse-vertical">
                    <div className="alertbox error">
                        Es wurden noch keine Leistungen für diesen Auftrag ausgewählt. <a href="#" onClick={() => editHandle(order.id)}>Bitte wählen Sie die benötigten Services hier</a>.
                    </div>
                </div>
            }
        </div>
    </div>
);

export default OrderDetailMetaData;