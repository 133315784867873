import React, { Component, useState } from 'react';
import {
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import axios from 'axios';

import FormInput from './FormInput';
import Infobox from './Infobox';

import { setUserSession } from '../utils/Common';
import { GALAB_API_URL, GALAB_API_VERSION }  from "../api/Galab.instance";

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            error: '',
        };
    }

    setError(error) {
        this.setState(prevState => ({
            ...prevState,
            error: error
        }))
    }

    handleSubmit = event => {
        event.preventDefault();

        const { email, password } = this.state;

        if (event) {
            event.preventDefault();
        }

        axios.post(GALAB_API_URL + GALAB_API_VERSION + 'authentication_token', { username: email, password: password }).then(response => {
            setUserSession(response.data.token, { username: email, password: password });
            window.location.reload();
        }).catch(error => {
            if (error.response.status === 401) {
                this.setError(error.response.data.message);
            } else {
                this.setError('Something went wrong. Please try again later.');
            }
        });
    };

    handleChange = event => {
        const { value, name } = event.target;

        this.setState({ [name]: value });
    };

    validateForm() {
		return this.state.email.length > 0 && this.state.password.length > 0;
	}

    render() {
		return (
			<form onSubmit={this.handleSubmit}>
                {
                    (this.state.error != '') ? <Infobox msg={this.state.error} type="error" /> : null
                }
                <FormInput
                    id='email'
                    name='email'
                    type='text'
                    handleChange={this.handleChange}
                    value={this.state.email}
                    label='E-Mail'
                    required
                />
                <FormInput
                    id='password'
                    name='password'
                    type='password'
                    handleChange={this.handleChange}
                    value={this.state.password}
                    label='Passwort'
                    required
                />
				<div className="form-row">
                    {
                        (this.validateForm() === true) ?
                            <button className="button button-block" type="submit">
                                Fortfahren
                            </button>
                        :
                            <button className="button button-block" disabled={!this.validateForm()}>
                                Fortfahren
                            </button>
                    }
				</div>
                {/*
				<div className="form-row">
					<label>
						<div className="fancy-check">
							<input type="checkbox" />
							<i></i>
						</div>
						Angemeldet bleiben
					</label>
				</div>
				*/}
			</form>
		)
	}
}

export default LoginForm;