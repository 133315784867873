import React, { Component } from 'react';
import {
    Link,
    withRouter
} from 'react-router-dom';
import { toast } from 'react-toastify';

import AuditPlanList from '../components/AuditPlanList';
import OrderPreview from '../components/OrderPreview';
import OrderDetailForm from '../components/OrderDetailForm';
import InlineForm from '../components/InlineForm';
import ServiceList from '../components/ServiceList';
import Spinner from '../components/Spinner';
import Tooltip from '../components/Tooltip';
import ServiceListmSkeleton from "../components/ServiceListSkeleton";
import AuditPlanListSkeleton from "../components/AuditPlanListSkeleton";

import galabApi from "../api/Galab.instance";

import {BASE_ORDER, getOrderById, TRANSITION_PLACE_ORDER} from '../utils/Order';

class CreateOrderForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoadingSave: false,
            showLoadingSend: false,
            showLoadingServices: false,
            showLoadingAuditPlans: false,
            buttonText: (this.props.buttonText) ? this.props.buttonText : 'Auftrag anlegen',
            customFieldData: [],
            auditPlanData: [],
            auditPlanDataFiltered: [],
            auditDataFilterQuery: '',
            serviceData: [],
            serviceDataFiltered: [],
            serviceDataFilterQuery: '',
            showServiceList: true,
            order: (this.props.order) ? this.props.order : BASE_ORDER
        };
    }

    componentDidMount() {
        this.setState({
            showLoadingServices: true,
            showLoadingAuditPlans: true,
        });

        galabApi.get('custom_fields').then(response => {
            this.setState(prevState => ({
                customFieldData: response.data['hydra:member'],
                order: {
                    ...prevState.order,
                    customFieldData: response.data['hydra:member']
                }
            }));
        }).catch(error => {});

        galabApi.get('analysis_services').then(response => {
            this.setState(prevState => ({
                serviceData: response.data['hydra:member'],
                serviceDataFiltered: response.data['hydra:member'],
                showLoadingServices: false,
            }))
        }).catch(error => {});

        galabApi.get('analysis_service_groups').then(response => {
            this.setState(prevState => ({
                auditPlanData: response.data['hydra:member'],
                auditPlanDataFiltered: response.data['hydra:member'],
                showLoadingAuditPlans: false,
            }))
        }).catch(error => {});

        if(typeof this.props.orderId !== 'undefined') {
            getOrderById(this.props.orderId)
                .then(function(response) {
                    this.setState(prevState => ({
                        order: {
                            ...prevState.order,
                            ...response.data
                        }
                    }));

                    if(this.state.order.customFieldData.length === 0) {
                        this.setState(prevState => ({
                            order: {
                                ...prevState.order,
                                customFieldData: this.state.customFieldData
                            }
                        }))
                    }
                }.bind(this))
                .catch(error => console.error('Outside error:', error));
        }
    }

    addOrder = (type) => {
        if(
            this.validateFormForSend() === true &&
            type === 'send'
        ) {
            this.setState({
                showLoadingSend: true
            });

            this.state.order.transition = TRANSITION_PLACE_ORDER;
        } else {
            this.setState({
                showLoadingSave: true
            });
        }

        if(this.state.order.id !== null && typeof this.state.order.id !== 'undefined') {
            galabApi.put('order_requests/' + this.state.order.id,
                this.state.order
            ).then(response => {
                //console.log(response);
                //console.log(response.data);

                this.handleOrderRequestCallback(type, response.data);
            }).catch(error => {});
        } else {
            galabApi.post('order_requests',
                this.state.order
            ).then(response => {
                //console.log(response);
                //console.log(response.data);

                this.handleOrderRequestCallback(type, response.data);
            }).catch(error => {});
        }
    };

    addOrderAndSave = () => {
        this.addOrder('save');
    };

    addOrderAndSend = () => {
        this.addOrder('send');
    };

    handleOrderRequestCallback = (type, data) => {
        this.setState(prevState => ({
            order: {
                ...prevState.order,
                ...data
            }
        }));

        if(type === 'send') {
            this.props.history.push('/index/orders/add/transfersheet/' + data.id);
        } else {
            toast.success('Ihr Auftrag wurde gespeichert!');

            this.setState({
                showLoadingSave: false
            });
        }
    };

    handleTabs = ()=>{
        let setState = !this.state.showServiceList;

        this.setState({
            showServiceList: setState
        })
    };

    handleChange = event => {
        const { value, name } = event.target;

        let customfieldid = event.target.getAttribute('data-customfield');

        if(customfieldid !== null){
            const customfieldIndex = this.state.order.customFieldData.findIndex(element => element.id == customfieldid );

            let newcustomFieldData = [...this.state.order.customFieldData];
            newcustomFieldData[customfieldIndex] = {...newcustomFieldData[customfieldIndex], fieldValue: value}

            this.setState(prevState => ({
                order: {
                    ...prevState.order,
                    customFieldData: newcustomFieldData
                }
            }));
        } else {
            this.setState(prevState => ({
                order: {
                    ...prevState.order,
                    [name]: value
                }
            }));
        }
    };

    addAuditPlan = (services) => {
        let servicesToAdd = [];

        services.map((item) => {
            if (!this.state.order.analysisServiceData.some(serviceItem => serviceItem.id === item.id)) {
                servicesToAdd.push(item);
            }
        });

        this.setState(prevState => ({
            order: {
                ...prevState.order,
                analysisServiceData: [
                    ...prevState.order.analysisServiceData,
                    ...servicesToAdd
                ]
            }
        }));
    };

    handleList = (type, id, name, serviceId)=> {
        if(type === 'add') {
            this.setState(prevState => ({
                order: {
                    ...prevState.order,
                    analysisServiceData: [
                        ...prevState.order.analysisServiceData,
                        {
                            id: id,
                            name: name,
                            serviceId: serviceId
                        }
                    ]
                }
            }));
        } else { // remove
            var serviceArray = [...this.state.order.analysisServiceData];

            serviceArray.some((serviceItem, index) => {
                if(serviceItem.id === id) {
                    serviceArray.splice(index, 1);
                }
            });

            this.setState(prevState => ({
                order: {
                    ...prevState.order,
                    analysisServiceData: [
                        ...serviceArray
                    ]
                }
            }));
        }
    };

    handleFilterServiceList = (event) => {
        const { value } = event.target;

        this.setState(
            { serviceDataFilterQuery: value },
            () => this.filterServiceList()
        );
    };

    filterServiceList = () => {
        let serviceData = this.state.serviceData;
        let query = this.state.serviceDataFilterQuery.toLowerCase();

        serviceData = serviceData.filter(function(item) {
            return item.name.toLowerCase().search(query) !== -1 || item.id.toString().search(query) !== -1; // returns true or false
        });

        this.setState({ serviceDataFiltered: serviceData });
    };

    handleFilterAuditList = (event) => {
        const { value } = event.target;

        this.setState(
            { auditDataFilterQuery: value },
            () => this.filterAuditList()
        );
    };

    filterAuditList = () => {
        let auditData = this.state.auditPlanData;
        let query = this.state.auditDataFilterQuery.toLowerCase();

        auditData = auditData.filter(function(item) {
            return item.name.toLowerCase().search(query) !== -1; // returns true or false
        });

        this.setState({ auditPlanDataFiltered: auditData });
    };

    validateFormForSave() {
        let order = this.state.order;

        return order.name.length > 0;
    }

    validateFormForSend() {
        let order = this.state.order;

        if(this.state.order.state !== 'incomplete' || this.state.order.state === 'created') {
            return false;
        }

        return order.name.length > 0 &&
            order.batchNumber.length > 0 &&
            order.orderDetails.length > 0 &&
            order.analysisServiceData.length > 0;
    }

    render() {
        const { serviceData, serviceDataFiltered, order, auditPlanData, auditPlanDataFiltered, buttonText, customFieldData } = this.state;

        return (
            <div className="flex-grid-container">
                <div className="w-66 gutter-right border-radius">
                    <div className="flex-grid-container inner-border">
                        <div className="w-50">
                            <div className="content-inner use-space scrollable">
                                <h2>Probendetails</h2>
                                <OrderDetailForm
                                    order={order}
                                    customFieldData={customFieldData}
                                    handleChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="w-50">
                            <div className="content-inner collapse-bottom">
                                <h2>Leistungsübersicht</h2>
                            </div>
                            <div className="tabs use-space parent scrollable-parent">
                                <ul className="tab-navi">
                                    <li onClick={this.handleTabs} className={this.state.showServiceList ? 'active' : ''}>
                                        Alle Leistungen ({serviceData.length})
                                    </li>
                                    <li onClick={this.handleTabs} className={!this.state.showServiceList ? 'active' : ''}>
                                        Prüfpläne ({auditPlanData.length})
                                    </li>
                                </ul>
                                {this.state.showServiceList ?(
                                    <div className="tab-content use-space parent scrollable-parent">
                                        <div className="content-inner">
                                            <InlineForm
                                                handleChange={this.handleFilterServiceList}
                                                value={this.state.serviceDataFilterQuery}
                                            />
                                        </div>
                                        {this.state.showLoadingServices === true ?
                                            <ServiceListmSkeleton />
                                            :
                                            <ServiceList
                                                items={serviceDataFiltered}
                                                selectedItems={order.analysisServiceData}
                                                handleList={this.handleList}
                                                handleListType='add'
                                            />
                                        }
                                    </div>
                                ) : (
                                    <div className="tab-content use-space parent scrollable-parent">
                                        <div className="content-inner">
                                            <InlineForm
                                                handleChange={this.handleFilterAuditList}
                                                value={this.state.auditDataFilterQuery}
                                            />
                                        </div>
                                        {this.state.showLoadingAuditPlans === true ?
                                            <AuditPlanListSkeleton />
                                            :
                                            <AuditPlanList
                                                clickHanlde={this.addAuditPlan}
                                                items={auditPlanDataFiltered}
                                            />
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-33 gutter-left border-radius">
                    <div className="flex-grid-container">
                        <div className="w-100">
                            <OrderPreview
                                order={order}
                                handleList={this.handleList}
                                handleListType='remove'
                            />
                            <div className="flex-panel-footer multi-buttons">
                                {(this.validateFormForSave() === true) ?
                                    <a href="#" className="spinner-wrap" onClick={() => this.addOrderAndSave()}>
                                        {this.state.showLoadingSave !== false &&
                                            <Spinner type="small" />
                                        }
                                        <span>Auftrag speichern</span>
                                    </a>
                                    :
                                    <Tooltip content="Bitte geben Sie Ihrer Probe eine Bezeichnung, bevor Sie in speichern können.">
                                        <span>Auftrag speichern</span>
                                    </Tooltip>
                                }
                                {(this.validateFormForSend() === true) ?
                                    <button className="button button-hightlight less-padding spinner-wrap" onClick={() => this.addOrderAndSend()}>
                                        {this.state.showLoadingSend !== false &&
                                            <Spinner type="small" />
                                        }
                                        <span>Auftrag versenden</span>
                                    </button>
                                    :
                                    <Tooltip content={(this.state.order.state !== 'incomplete' || this.state.order.state === 'created') ? 'Bitte die Probe erst speichern bevor sie versendet werden kann.' : 'Probe unvollständig. Bitte füllen Sie alle Pflichtfelder.'}>
                                        <button className="button button-hightlight less-padding" disabled={!this.validateFormForSend()}>
                                            Auftrag versenden
                                        </button>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CreateOrderForm);