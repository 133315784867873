import React, { Component } from 'react';

import { getLabelByName } from '../utils/Forms';
import Moment from "moment/moment";

class OrderMetaData extends Component {
    render() {
        const { order } = this.props;

        return (
    <dl>
        <dt>{getLabelByName('batchNumber')}</dt>
        <dd>{order.batchNumber}</dd>

        <dt>{getLabelByName('customerOrderNumber')}</dt>
        <dd>{order.customerOrderNumber}</dd>

        <dt>{getLabelByName('orderDetails')}</dt>
        <dd>{order.orderDetails}</dd>

        <dt>{getLabelByName('customerProductNumber')}</dt>
        <dd>{order.customerProductNumber}</dd>

        <dt>{getLabelByName('comment')}</dt>
        <dd>{order.comment}</dd>

        <dt>{getLabelByName('bestBefore')}</dt>
        <dd>{(order.bestBefore !== '') ? Moment(order.bestBefore).format('DD.MM.YYYY') : ''}</dd>
        {
            order.customFieldData.map(({ id, fieldType, name, fieldValue }) => (
                <div key={id}>
                    <dt>{name}</dt>
                    <dd>{fieldValue}</dd>
                </div>
            ))
        }
    </dl>
)}};

export default OrderMetaData;