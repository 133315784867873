import React from 'react';

const FormInput = ({ handleChange, label, ...otherProps }) => (
    <div className="form-row">
        {/*otherProps.type === 'password' ? (
            <a href="#" className="fl-right">Passwort vergessen?</a>
        ) : null*/}
        {label ? (
            <label htmlFor={otherProps.id}>
                {label}
            </label>
        ) : null}
        <input className="form-input" data-customfield={otherProps.customfieldid} onChange={handleChange} {...otherProps} />
    </div>
);

export default FormInput;