import React, { Component } from 'react';

import DocumentSvg from "../assets/img/document.svg";
import galabApi from "../api/Galab.instance";
import Spinner from "../components/Spinner";
import Tooltip from "../components/Tooltip";
import Moment from "moment/moment";

class File extends Component {
    constructor(props) {
        super(props);

        console.log(this.props);

        this.state = {
            name: this.props.sourceFileName,
            size: this.props.fileSize,
            order_name: this.props.orderRequestName,
            created: this.props.createdAt,
            file_token: this.props.uploadToken,
            showLoading: false,
        };
    }

    downloadFile = (event) => {
        event.preventDefault();

        if(this.state.file_token !== '') {
            this.setState(prevState => ({
                showLoading: true,
            }));

            const FileDownload = require("js-file-download");

            galabApi.get('file_upload/'+this.state.file_token, {
                responseType: 'blob'
            }).then(response => {
                FileDownload(response.data, this.state.name);

                this.setState(prevState => ({
                    showLoading: false,
                }));
            }).catch(error => {
                /*
                if (error.response.status === 401) setError(error.response.data.message);
                else setError("Something went wrong. Please try again later.");
                */
            });
        } else {
            alert('Missing Token');
        }
    };

    humanFileSize = (size) => {
        var i = Math.floor( Math.log(size) / Math.log(1024) );
        return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    render() {
        return(
            <a className="block-link" onClick={this.downloadFile}>
                <div className="file">
                    <img className="svg" src={DocumentSvg}/>
                    <div className="file-content">
                        <div className="name">{this.state.name}</div>
                        <div className="order-name">{this.state.order_name}</div>
                        <div className="date">{Moment(this.state.created).format('DD.MM.YYYY')} &bull; {this.humanFileSize(this.state.size)}</div>
                    </div>
                    {this.state.showLoading === false ?
                        <i className="icon-download"></i>
                        :
                        <Tooltip content="Das bereitstellen von großen Dateien kann einen Moment dauern.">
                            <Spinner type="small" />
                        </Tooltip>
                    }
                </div>
            </a>
        )
    }
}

export default File;