import React, { Component } from 'react';
import {
    withRouter
} from 'react-router-dom';

import Breadcrumb from '../components/Breadcrumb';
import CreateOrderForm from '../components/CreateOrderForm';

class CreateOrder extends Component {
    leavePage = (event)=>{
        event.preventDefault();

        if (window.confirm('Wollen Sie die Seite wirklich verlassen?')) {
			this.props.history.push('/index/orders');
        }
	};

    render() {
		return (
			<div>
                <div className="fl-right back-btn-wrap">
					<a href="#" onClick={this.leavePage} className="button button-hightlight collapse-left">
						<i className="icon-chevron-left"></i> Zurück zur Auftragsübersicht
					</a>
                </div>
				<Breadcrumb children={
					[
						{
							name: 'Auftragsübersicht',
							to: '/index/orders'
						},
						{
							name: 'Auftrag anlegen'
						}
					]
				} />
				<div className="max-height-content-container">
					<CreateOrderForm />
				</div>
			</div>
		)
	}
}

export default withRouter(CreateOrder);