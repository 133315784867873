import React from 'react';

import ActionIcons from "./ActionIcons";
import Status from "./Status";

const CompanyListRow = ({ id, viewHandle, previewHandle, editHandle, copyHandle, deleteHandle, company_name, company_id, status_type, status_value, best_before, delivery_date, created }) => (
    <tr>
		<td><a href="#" onClick={() => viewHandle(id)}><strong>{company_name + ' ' + id}</strong></a></td>
		<td>
			<div className="row-action">
				<ActionIcons
					actionId={id}
					statusValue={status_value}
                    viewHandle={viewHandle}
                    editHandle={editHandle}
                    copyHandle={copyHandle}
                    deleteHandle={deleteHandle}
				/>
			</div>
		</td>
		<td>{created}</td>
		<td>
			{company_id}
		</td>
		<td>{delivery_date}</td>
	</tr>
);

export default CompanyListRow;