import React, { Component } from "react";
import { CSSTransition } from 'react-transition-group';

// import debounce from "lodash/debounce";

class TooltipChild extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            coords: this.props.coords,
            isOn: false,
            forceHidden: false,
            wrapClasses: 'fancy-tooltip'
        };
    }

    componentDidMount() {
        this._isMounted = true;

        window.addEventListener('scroll', this.removeOnScroll, true);

        setTimeout(() => {
            if (this._isMounted) {
                this.setState({
                    isOn: true
                });

                setTimeout(() => {
                    this.setState({
                        wrapClasses: 'fancy-tooltip active'
                    });
                }, 100);
            }
        }, 500);
    };

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('scroll', this.removeOnScroll, true);
    };

    removeOnScroll = () => {
        if (this._isMounted) {
            this.setState({
                isOn: false,
                forceHidden: true,
            });
        }

        window.removeEventListener('scroll', this.removeOnScroll, true);
    };

    mouseEnter = () => {
        if(
            typeof this.props.forceEnter === 'undefined'
        ) {
            this.removeOnScroll();
        }
    };

    render() {
        return (
            <div>
            {
                this.state.isOn && !this.state.forceHidden &&
                <CSSTransition in={this.state.isOn} timeout={1200} classNames="my-node">
                    <div
                        style={{...this.state.coords}}
                        className={this.state.wrapClasses}
                        onMouseEnter={this.mouseEnter}
                    >
                        <div className="fancy-tooltip-msg-wrap">
                            <div className="fancy-tooltip-msg" role="tooltip">
                                <div>{this.props.children}</div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            }
            </div>
        )
    }
};

export default TooltipChild;
