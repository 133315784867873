import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Breadcrumb extends Component {
	render() {
		return (
			<div className="breadcrumb">
				<ul>
					{this.props.children.map(function(child, index) {
						if(typeof child.to === 'undefined') {
							return (
								<li key={index}><h1>{child.name}</h1></li>
							)
						} else {
							return (
								<li key={index}><Link to={child.to}>{child.name}</Link></li>
							)
						}
					})}
				</ul>
			</div>
		)
	}
}

export default Breadcrumb;