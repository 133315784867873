import React, { Component } from 'react';
import {
    Link,
    withRouter
} from "react-router-dom";

import AuditPreview from '../components/AuditPreview';
import AuditDetailForm from '../components/AuditDetailForm';
import InlineForm from '../components/InlineForm';
import ServiceList from '../components/ServiceList';

import AUDIT_PLAN_DATA from "../data/auditplan.data";
import galabApi from "../api/Galab.instance";

import { BASE_ORDER } from '../utils/Order';

class EditAuditForm extends Component {
    constructor(props) {
        super(props);

        let audit = {
            name: '',
            services: []
        };

        if(typeof this.props.audit !== 'undefined') {
            audit = this.props.audit;
        }

        this.state = {
            auditPlanData: AUDIT_PLAN_DATA,
            serviceData: [],
            serviceDataFiltered: [],
            serviceDataFilterQuery: '',
            showServiceList: true,
            order: BASE_ORDER,
            audit: audit
        };

        if(typeof this.props.audit !== 'undefined') {
            this.setState({
                audit: this.props.audit
            });
        }
    }

    componentDidMount() {
        galabApi.get('analysis_services').then(response => {
            this.setState(prevState => ({
                serviceData: response.data['hydra:member'],
                serviceDataFiltered: response.data['hydra:member'],
            }))
        }).catch(error => {
            /*
            if (error.response.status === 401) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
            */
        });
    }

    handleTabs = ()=>{
        let setState = !this.state.showServiceList;

        this.setState({
            showServiceList: setState
        })
    };

    handleChange = event => {
        const { value, name } = event.target;

        this.setState(prevState => ({
            audit: {
                ...prevState.audit,
                [name]: value
            }
        }));
    };
/*
    addAuditPlan = (services) => {
        let servicesToAdd = [];

        services.map((item) => {
            if (!this.state.order.services.some(serviceItem => serviceItem.id === item.id)) {
                servicesToAdd.push(item);
            }
        });

        this.setState(prevState => ({
            order: {
                ...prevState.order,
                services: [
                    ...prevState.order.services,
                    ...servicesToAdd
                ]
            }
        }));
    };
*/

    handleList = (type, id, name, serviceId)=> {
        if(type === 'add') {
            this.setState(prevState => ({
                audit: {
                    ...prevState.audit,
                    services: [
                        ...prevState.audit.services,
                        {
                            id: id,
                            name: name,
                            serviceId: serviceId,
                        }
                    ]
                }
            }));
        } else { // remove
            var serviceArray = [...this.state.audit.services];

            serviceArray.some((serviceItem, index) => {
                if(serviceItem.id === id) {
                    serviceArray.splice(index, 1);
                }
            });

            this.setState(prevState => ({
                audit: {
                    ...prevState.audit,
                    services: [
                        ...serviceArray
                    ]
                }
            }));
        }
    };

    handleFilterList = (event) => {
        const { value } = event.target;

        this.setState(
            { serviceDataFilterQuery: value },
            () => this.filterList()
        );
    };

    filterList = () => {
        let serviceData = this.state.serviceData;
        let query = this.state.serviceDataFilterQuery.toLowerCase();

        serviceData = serviceData.filter(function(item) {
            return item.name.toLowerCase().search(query) !== -1 || item.id.toString().search(query) !== -1 || item.serviceId.toString().search(query) !== -1; // returns true or false
        });

        this.setState({ serviceDataFiltered: serviceData });
    };

    validateForm() {
        let audit = this.state.audit;

        return audit.name.length > 0 &&
            audit.services.length > 0;
    }

    render() {
        const { serviceData, serviceDataFiltered, audit, auditPlanData } = this.state;

        return (
            <div className="edit-audit-form-wrap flex-grid-container inner-border border-radius">
                <div className="w-50">
                    <div className="content-inner">
                        <AuditDetailForm
                            audit={audit}
                            handleChange={this.handleChange}
                        />
                        <InlineForm
                            handleChange={this.handleFilterList}
                            value={this.state.serviceDataFilterQuery}
                        />
                    </div>
                    <ServiceList
                        items={serviceDataFiltered}
                        selectedItems={audit.services}
                        handleList={this.handleList}
                        handleListType='add'
                    />
                </div>
                <div className="w-50">
                    <AuditPreview
                        audit={audit}
                        handleList={this.handleList}
                        handleListType='remove'
                    />
                    <div className="flex-panel-footer single-button">
                        {(this.validateForm() === true) ?
                            <button className="button button-block button-xl button-hightlight" onClick={() => this.props.addHandle(audit)}>
                                Prüfplan speichern
                            </button>
                            :
                            <button className="button button-block button-xl button-hightlight" disabled={!this.validateForm()}>
                                Prüfplan speichern
                            </button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EditAuditForm);