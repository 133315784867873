import React, { Component } from 'react';

import OrderMetaData from '../components/OrderMetaData';
import ServiceList from '../components/ServiceList';
import EditAuditForm from "./EditAuditForm";

class AuditPreview extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { audit } = this.props;

        return (
            <div className="use-space parent scrollable-parent">
                <div className="content-inner">
                    <h2 className="txtcenter">Prüfplan Zusammenfassung</h2>
                    <div className="infobox txtcenter">
                        <strong>“{audit.name}”</strong>
                    </div>
                    <strong>Leistungen ({audit.services.length})</strong>
                </div>
                <ServiceList
                    items={audit.services}
                    selectedItems={[]}
                    handleList={this.props.handleList}
                    handleListType='remove'
                />
            </div>
        )
    }
}

export default AuditPreview;