import React, { Component } from 'react';

import Breadcrumb from '../components/Breadcrumb';
import File from '../components/File';
import {BASE_ORDER} from "../utils/Order";
import galabApi from "../api/Galab.instance";
import FileSkeleton from "../components/FileSkeleton";

	
class Certificates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoading: 'Lade Zertifikate',
            showLoadingLayout: false,
            certificates: [],
        };
    }

    componentDidMount() {
        this.setState({
            showLoadingLayout: true,
        });

        galabApi.get('file_uploads').then(response => {
            this.setState(prevState => ({
                certificates: response.data,
                showLoadingLayout: false,
            }))
        }).catch(error => {
            /*
            if (error.response.status === 401) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
            */
        });
    }

	render() {
		return (
			<div>
                <Breadcrumb children={
                    [
                       {
                            name: 'Alle Zertifikate'
                        }
                    ]
                } />
                <div className="content">
                    <div className="certificates max-height-content-container">
                        <div className="flex-grid-container border-radius">
                            <div className="w-100 content-inner scrollable-parent">
                                <div className="use-space scrollable">
                                    {this.state.showLoadingLayout !== false ?
                                        <div>
                                            <h2 className="sticky-headline"><div className="skeleton-item" style={{width: '150px'}}></div></h2>
                                            <div className="files-list">
                                                <FileSkeleton/>
                                                <FileSkeleton/>
                                                <FileSkeleton/>
                                                <FileSkeleton/>
                                                <FileSkeleton/>
                                                <FileSkeleton/>
                                                <FileSkeleton/>
                                                <FileSkeleton/>
                                                <FileSkeleton/>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <h2 className="sticky-headline">Neuste Zertifikate</h2>
                                                {this.state.certificates.length > 0 ?
                                                    <div className="files-list">
                                                        {
                                                            this.state.certificates.map(({ id, ...otherOrderProps }) => (
                                                                <File key={id} id={id} {...otherOrderProps} />
                                                            ))
                                                        }
                                                    </div>
                                                    :
                                                    <div className="content-inner txtcenter">
                                                        <h2 className="offset-bottom-1">Es wurden keine Zertifikate gefunden!</h2>
                                                    </div>
                                                }
                                            {/*
                                            <hr/>
                                            <h2 className="sticky-headline">Alle Zertifikate</h2>
                                            <div className="files-list">
                                                <File/>
                                            </div>
                                            */}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		)
	}
}

export default Certificates;