import React, { Component } from 'react';

import Filter from "../components/Filter";
import CompanyListRow from "../components/CompanyListRow";
import Modal from "../components/Modal";
import OrderPreview from '../components/OrderPreview';
import OrderDetail from '../components/OrderDetail';
import CreateOrderForm from '../components/CreateOrderForm';

import COMPANY_DATA from '../data/company.data';

class CompanyList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companies: COMPANY_DATA,
            orderPreview: null,
			orderDetail: null,
            orderEdit: null
        };
    }

    viewOrderPreview = (id)=>{
        let order = null;

        if(id !== null) {
            order = this.state.orders[id - 1]
        }

        this.setState({
            orderPreview: order
        })
    };

    viewOrder = (id)=>{
        let order = null;

        if(id !== null) {
            order = this.state.orders[id - 1]
        }

        this.setState({
            orderDetail: order
        })
    };


    editOrder = (id)=>{
    	let order = null;

    	if(id !== null) {
            order = this.state.orders[id - 1]
		}

        this.setState({
            orderEdit: order
        })
    };

    copyOrder = (id)=>{
        let copyOrder = null;

        if(id !== null) {
            copyOrder = {...this.state.orders[id - 1]};

            let orderName = copyOrder.product_name;

            copyOrder.product_name = orderName + ' (Kopie)';
            copyOrder.lot = '';
            copyOrder.number_of_samples = '';
            copyOrder.best_before = '';
        }

        this.setState({
            orderEdit: copyOrder
        });
    };

    deleteOrder = (id)=>{
        if(id !== null) {
            let order = this.state.orders[id - 1];

            alert('Wollen sie wirklich den Auftrag "' + order.product_name + '" wirklich löschen?');
        }
    };

	render() {
		const { companies, orderPreview, orderDetail, orderEdit } = this.state;

		return (
			<div>
                <div className="max-height-content-container">
                    <div className="flex-grid-container">
                        <div className="w-100">
                            <div className="content-inner use-space parent">
                                <Filter />
                                <div className="table-wrap use-space">
                                    <table className="table table-striped table-bordered sticky-header">
                                        <thead>
                                            <tr>
                                                <th colSpan="2">Unternehmen</th>
                                                <th>Erstellungsdatum</th>
                                                <th>Status</th>
                                                <th>Lieferdatum</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {companies.map(({ id, ...otherOrderProps }) => (
                                                <CompanyListRow
                                                    key={id} id={id} {...otherOrderProps}
                                                    viewHandle={this.viewOrder}
                                                    previewHandle={this.viewOrderPreview}
                                                    editHandle={this.editOrder}
                                                    copyHandle={this.copyOrder}
                                                    deleteHandle={this.deleteOrder} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    Pagniation
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {orderPreview !== null ?
                    <div className="list-detail-view-wrap">
                        <div className="list-detail-view">
                            <i className="close-trigger icon-close-solid" onClick={() => this.viewOrderPreview(null)}></i>
                            <OrderPreview order={orderPreview} />
                        </div>
                        <div className="list-detail-view-overlay" onClick={() => this.viewOrderPreview(null)}></div>
                    </div>
                    : null }
				{orderDetail !== null ?
                    <Modal closeHandle={this.viewOrder}>
                        <OrderDetail
                            order={orderDetail}
                            editHandle={this.editOrder}
                            copyHandle={this.copyOrder}
                            deleteHandle={this.deleteOrder}
                        />
                    </Modal>
                : null }
                {orderEdit !== null ?
                    <Modal closeHandle={this.editOrder}>
                        <CreateOrderForm order={orderEdit} />
                    </Modal>
				: null }
                {false ?
                    <Modal>
                        <CreateOrderForm order={this.state.orders[2]} />
                    </Modal>
                : null}
			</div>
		)
	}
}

export default CompanyList;