import React, { Component } from 'react';
import { Link } from "react-router-dom";

import InlineForm from "./InlineForm";

import { ORDER_STATES } from '../utils/Order';

class Filter extends Component {
    constructor(props) {
        super(props);

        this.state = {
        	showFilter: false,
            ...this.props.filter
        };
    }

    toggleFilter = (event) => {
        event.preventDefault();

		let showFilter = this.state.showFilter;

		this.setState({
            showFilter: !showFilter
        });
    }

	render() {
		return (
			<div className="filter">
				<div className="filter-row">
					<div className="filter-item">
						<InlineForm handleChange={this.props.handleChange} handleSubmit={this.props.handleSubmit} />
					</div>
					<div className="filter-item">
						{this.state.showFilter === false ?
							<a href="#" onClick={this.toggleFilter} className="button no-button"><i className="icon-plus"></i> Filter einblenden</a>
							:
							<a href="#" onClick={this.toggleFilter} className="button no-button active"><i className="icon-minus"></i> Filter ausblenden</a>
						}
					</div>
					<div className="fl-right">
						<Link to="/index/orders/csv" className="button button-ghost">CSV Upload</Link>
						<Link to="/index/orders/add" className="button">Auftrag anlegen</Link>
					</div>
				</div>
                {this.state.showFilter === true ?
                <div className="filter-row">
                    <div className="filter-item filter-status">
						{
                            Object.entries(ORDER_STATES).map(([key, value]) => (
								this.state.status.includes(key, 0) === true ?
                                    <span className="active" key={key} onClick={() => {this.props.handleStatusFilter(key)}}>
										<i className="icon-minus-outline"></i> {value}
									</span>
									:
                                    <span key={key} onClick={() => {this.props.handleStatusFilter(key)}}>
										<i className="icon-add-outline"></i> {value}
									</span>

							))
						}
					</div>
				</div>
				:null}
			</div>
		)
	}
}

export default Filter;