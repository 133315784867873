import React, { Component } from 'react';

class HeaderNotification extends Component {
	render() {
		return (
			<div className="header-notification">
				<div className="notification-trigger header-action-trigger">
					<i className="icon-bell"></i>
					<span>
						9+
					</span>
				</div>
				<div className="notification-list">
					Notification List
				</div>
			</div>
		)
	}
}

export default HeaderNotification;