import React, { Component } from 'react';

class InlineForm extends Component {
    submitForm = (event) => {
        event.preventDefault();

        if(typeof(this.props.handleSubmit) === 'function') {
            this.props.handleSubmit();
        }
    };

	render() {
		return (
			<form className="inline-form" onSubmit={this.submitForm}>
				<input type="text" value={this.props.value} onChange={this.props.handleChange} />
				<button type="submit">
					<i className="icon-magnifying-glass"></i>
				</button>
			</form>
		)
	}
}

export default InlineForm;