import React from 'react';

const ListRowSkeleton = () => {
    let skeletonWidth = (Math.random() > 0.5) ? 60 : 40;

    return (
        <tr className="skeleton-item-list-row">
            <td colSpan="2">
                <div className="skeleton-item" style={{width: skeletonWidth + '%'}}></div>
            </td>
            <td>
                <div className="skeleton-item" style={{width: '100px'}}></div>
            </td>
            <td>
                <div className="skeleton-item" style={{width: '80px'}}></div>
            </td>
            <td>
                <div className="skeleton-item" style={{width: '100px'}}></div>
            </td>
        </tr>
    )
};

export default ListRowSkeleton;