import React, { Component } from 'react';
import {
    Link,
    withRouter
} from 'react-router-dom';
import { removeUserSession } from '../utils/Common';

import Avatar from "./Avatar";
import galabApi from "../api/Galab.instance";

class HeaderNotification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            company: '',
            emailAddress: '',
            firstName: '',
            lastName: '',
            salutation: null
        }
    }

    componentDidMount() {
        galabApi.get('me').then(response => {
            this.setState({
                ... response.data
            });
        });
    }

    // handle click event of logout button
    handleLogout = () => {
        const { history } = this.props;

        removeUserSession();
        // history.push('/');
        window.location.reload();
    };

	render() {
		return (
			<div className="header-usermenu">
				<div className="header-usermenu-trigger">
					<i className="icon-user"></i> {this.state.company}
				</div>
				<div className="header-usermenu-dd">
                    <div className="header-usermenu-dd-inner">
                        <div className="header-usermenu-row">
                            <div><strong>{this.state.company}</strong></div>
                            <div>{this.state.firstName} {this.state.lastName}</div>
                            <em>{this.state.emailAddress}</em>
                        </div>
                        <div className="header-usermenu-row">
                            <button className="button button-ghost button-block" onClick={this.handleLogout}>Ausloggen</button>
                        </div>
                    </div>
                </div>
			</div>
		)
	}
}

export default HeaderNotification;