import React from 'react';

import ServiceItemSkeleton from '../components/ServiceItemSkeleton';

const ServiceListSkeleton = () => {
    return (
        <div className="service-list use-space parent scrollable">
            <div className="service-list-inner use-space">
                <ServiceItemSkeleton />
                <ServiceItemSkeleton />
                <ServiceItemSkeleton />
                <ServiceItemSkeleton />
                <ServiceItemSkeleton />
                <ServiceItemSkeleton />
            </div>
        </div>
    )
}

export default ServiceListSkeleton;