import React, { Component } from 'react';

import AuditPlanList from '../components/AuditPlanList';
import Breadcrumb from '../components/Breadcrumb';
import EditAuditForm from '../components/EditAuditForm';
import InlineForm from '../components/InlineForm';
import Modal from "../components/Modal";
import ServiceList from '../components/ServiceList';
import FullScreenLoader from '../components/FullScreenLoader';

import MissingServiceSvg from "../assets/img/csv-missing-service.svg";
import galabApi from "../api/Galab.instance";
import ServiceListmSkeleton from "../components/ServiceListSkeleton";
import AuditPlanListSkeleton from "../components/AuditPlanListSkeleton";

class Services extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoading: 'Lade Services',
            showLoadingServices: false,
            showLoadingAuditPlans: false,
            audits: [],
            auditsFiltered: [],
            auditsFilterQuery: '',
            services: [],
            servicesFiltered: [],
            servicesFilterQuery: '',
            auditEdit: null
        };
    }

    componentDidMount() {
        this.loadAnalysisServicesFromApi();
        this.loadAnalysisServiceGroupsFromApi();
    }

    loadAnalysisServicesFromApi() {
        this.setState({
            showLoading: 'Lade Services',
            showLoadingServices: true,
        });

        galabApi.get('analysis_services').then(response => {
            this.setState(prevState => ({
                services: response.data['hydra:member'],
                servicesFiltered: response.data['hydra:member'],
                showLoadingServices: false,
            }))
        }).catch(error => {
            /*
            if (error.response.status === 401) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
            */
        });
    }

    loadAnalysisServiceGroupsFromApi() {
        this.setState({
            showLoading: 'Lade Services',
            showLoadingAuditPlans: true,
        });

        galabApi.get('analysis_service_groups').then(response => {
            this.setState(prevState => ({
                audits: response.data['hydra:member'],
                auditsFiltered: response.data['hydra:member'],
                showLoading: false,
                showLoadingAuditPlans: false,
            }))
        }).catch(error => {
            /*
            if (error.response.status === 401) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
            */
        });
    }

    getAuditById = (id) => {
        let audit = null;

        if(id !== null) {
            let auditById = this.state.audits.filter(obj => {
                return obj.id === id
            });

            audit = auditById[0];
        }

        return audit;
    };

    handleFilterServiceList = (event) => {
        const { value } = event.target;

        this.setState(
            { servicesFilterQuery: value },
            () => this.filterServiceList()
        );
    };

    filterServiceList = () => {
        let serviceData = this.state.services;
        let query = this.state.servicesFilterQuery.toLowerCase();

        serviceData = serviceData.filter(function(item) {
            return item.name.toLowerCase().search(query) !== -1 || item.id.toString().search(query) !== -1; // returns true or false
        });

        this.setState({ servicesFiltered: serviceData });
    };

    handleFilterAuditList = (event) => {
        const { value } = event.target;

        this.setState(
            { auditsFilterQuery: value },
            () => this.filterAuditList()
        );
    };

    filterAuditList = () => {
        let auditData = this.state.audits;
        let query = this.state.auditsFilterQuery.toLowerCase();

        auditData = auditData.filter(function(item) {
            return item.name.toLowerCase().search(query) !== -1; // returns true or false
        });

        this.setState({ auditsFiltered: auditData });
    };

    editAudit = (id)=>{
        let audit = null;

        if(id !== null) {
            audit = this.getAuditById(id);
        }

        this.setState({
            auditEdit: audit
        });
    };

    copyAudit = (id)=>{
        let copyAudit = null;

        if(id !== null) {
            let audit = this.getAuditById(id);

            copyAudit = {... audit};

            let auditName = copyAudit.name;

            copyAudit.name = auditName + ' (Kopie)';
        }

        this.setState({
            auditEdit: copyAudit
        });
    };

    addAuditPlan = (auditplan) => {
        if(auditplan.id !== null && typeof auditplan.id !== 'undefined') {
            galabApi.put('analysis_service_groups/' + auditplan.id,
                auditplan
            ).then(response => {
                //if(response.status === 200) {
                    this.loadAnalysisServiceGroupsFromApi();

                    this.setState({
                        auditEdit: null
                    });
                //}
            }).catch(error => {
                /*
                if (error.response.status === 401) setError(error.response.data.message);
                else setError("Something went wrong. Please try again later.");
                */
            });
        } else {
            galabApi.post('analysis_service_groups',
                auditplan
            ).then(response => {
                //if(response.status === 200) {
                    this.loadAnalysisServiceGroupsFromApi();

                    this.setState({
                        auditEdit: null
                    });
                //}
            }).catch(error => {
                /*
                if (error.response.status === 401) setError(error.response.data.message);
                else setError("Something went wrong. Please try again later.");
                */
            });
        }
    };

    deleteAudit = (id)=>{
        if(id !== null) {
            if (window.confirm('Wollen Sie den Prüfplan "' + id + '" wirklich löschen?')) {
                galabApi.delete('analysis_service_groups/' + id).then(response => {
                    this.loadAnalysisServiceGroupsFromApi();
                }).catch(error => {
                    /*
                    if (error.response.status === 401) setError(error.response.data.message);
                    else setError("Something went wrong. Please try again later.");
                    */
                });
            }
        }
    };

	render() {
        const { auditEdit } = this.state;

		return (
			<div>
                <Breadcrumb children={
                    [
                        {
                            name: 'Leistungen & Prüfpläne'
                        }
                    ]
                } />
                <div className="services-overview">
					<div className="content">
                        <div className="max-height-content-container">
                            <div className="flex-grid-container inner-border">
                                <div className="w-33">
                                    <div className="content-inner">
                                        <h2>Ihre Leistungsübersicht</h2>
                                        <InlineForm
                                            handleChange={this.handleFilterServiceList}
                                            value={this.state.servicesFilterQuery}
                                        />
                                    </div>
                                    {this.state.showLoadingServices === true ?
                                        <ServiceListmSkeleton />
                                        :
                                        <ServiceList
                                            items={this.state.servicesFiltered}
                                            showFavourite={true}
                                            selectedItems={[]}
                                        />
                                    }
                                </div>
                                <div className="w-33">
                                    <div className="content-inner">
                                        <h2>Ihre Prüfpläne</h2>
                                        <div className="flex-filter">
                                            <InlineForm
                                                handleChange={this.handleFilterAuditList}
                                                value={this.state.auditsFilterQuery}
                                            />
                                            <a href="#" className="nowrap" onClick={() => this.editAudit()}><i className="icon-plus"></i> neuer Prüfplan</a>
                                        </div>
                                    </div>
                                    {this.state.showLoadingAuditPlans === true ?
                                        <AuditPlanListSkeleton />
                                        :
                                        this.state.auditsFiltered.length > 0 ?
                                            <AuditPlanList
                                                items={this.state.auditsFiltered}
                                                editHandle={this.editAudit}
                                                copyHandle={this.copyAudit}
                                                deleteHandle={this.deleteAudit}
                                            />
                                            :
                                            <div className="audit-plan-list use-space parent scrollable">
                                                <div className="audit-plan-list-inner use-space">
                                                    <div className="content-inner txtcenter">
                                                        <h2 className="offset-bottom-1">Keine Prüfpläne vorhanden</h2>
                                                        <a href="#" className="button" onClick={() => this.editAudit()}>Prüfplan erstellen</a>
                                                    </div>
                                                </div>
                                            </div>

                                    }
                                </div>
                                <div className="w-33 txtcenter justify-center">
                                    <div className="content-inner">
                                        <img src={MissingServiceSvg} className="svg" />
                                        <h2 className="has-subline">Fehlende Leistungen?</h2>
                                        <p>
                                            Sie würden gerne Ihren Leistungsumfang erweitern? Dann Kontaktieren Sie unseren Kundensupport
                                        </p>
                                        <a href="mailto:portal@galab.com?subject=Fehlende Leistungen" className="button">Zum Kundensupport</a>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
				</div>
                {auditEdit !== null ?
					<Modal closeHandle={this.editAudit}>
						<EditAuditForm audit={auditEdit} addHandle={this.addAuditPlan} />
					</Modal>
				: null }
                {/*
                    this.state.showLoading !== false ?
                        <FullScreenLoader msg={this.state.showLoading} />
                        : null
                */}
			</div>
		)
	}
}

export default Services;