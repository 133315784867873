import React from 'react';

const ServiceItemSkeleton = () => {
    let skeletonWidth = (Math.random() > 0.5) ? 180 : 150;

    return (
        <div className="service-item">
            <div className="service-item-content">
                <strong><div className="skeleton-item" style={{width: skeletonWidth + 'px'}}></div></strong>
                <div className="skeleton-item" style={{width: '60px'}}></div>
            </div>
        </div>
    )
}


export default ServiceItemSkeleton;