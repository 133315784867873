import React, {Component} from 'react';

import ActionIcons from "./ActionIcons";
import Status from "./Status";
import Moment from 'moment';
import Tooltip from './Tooltip';

class ListRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoverClass: ''
        };
    }

    mouseEnter = (event) => {
        this.setState({
            hoverClass: 'tr-hover-class'
        });
    };

    mouseLeave = (event) => {
        this.setState({
            hoverClass: ''
        });
    };

	render() {
        const { id, viewHandle, previewHandle, editHandle, copyHandle, deleteHandle, articleNumber, state, createdAt, name, deliveryDate, readonly } = this.props;

        return (
			<tr className={this.state.hoverClass} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
				<td>
					{state === 'incomplete' ?
						<strong className="ui-link" onClick={() => editHandle(id)}>
							{name + ' (#' + articleNumber + ')'}
						</strong>
						:
						<strong className="ui-link" onClick={() => viewHandle(id)}>
							{name + ' (#' + articleNumber + ')'}
						</strong>
					}
				</td>
				<td>
					<div className="row-action">
						<ActionIcons
							actionId={id}
							actionName={name}
							actionArticleNumber={articleNumber}
							readonly={readonly}
							viewHandle={viewHandle}
							editHandle={editHandle}
							copyHandle={copyHandle}
							deleteHandle={deleteHandle}
						/>
					</div>
				</td>
				<td>
                    <Tooltip content={Moment(createdAt).format('H:mm') + ' Uhr'}>
                        {Moment(createdAt).format('DD.MM.YYYY')}
                    </Tooltip>
				</td>
				<td>
					<Status
						state={state}
						editHandle={editHandle}
					/>
				</td>
				<td>
					{typeof deliveryDate !== 'undefined' ?
						Moment(deliveryDate).format('DD.MM.YYYY') : null
					}
				</td>
			</tr>
		)
	};
};

export default ListRow;