import React, { Component } from 'react';
import { Route } from "react-router-dom";

import Header from "./Header";
import Breadcrumb from "./Breadcrumb";
import CompanyList from "../pages/CompanyList";
	
class Backend extends Component {
	render() {
		return (
			<div className="main-wrap">
				<Header />
				<div className="content-wrap">
					<div className="container">
						<Route exact path="/backend">
							<Breadcrumb children={
								[
									{
										name: 'Kundenübersicht'
									}
								]
							} />
							<div className="content">
								<CompanyList />
							</div>
						</Route>
					</div>
				</div>
			</div>
		)
	}
}

export default Backend;