export const getLabelByName = (name) => {
    const labels = {
        name : 'Probenbezeichnung',
        articleNumber : 'Galab Probennummer ',
        batchNumber: 'Lot-/ Chargennummer',
        customerOrderNumber: 'Auftrags-/ Bestellnummer',
        orderDetails: 'Probenartdetails (Probenart/ Matrix)',
        customerProductNumber: 'Waren-/Artikelnummer',
        comment: 'Anmerkungen',
        bestBefore: 'Mindesthaltbarkeitsdatum'
    };

    return labels[name];
}
