import React, { Component } from 'react';

import File from '../components/File';
import FileSkeleton from '../components/FileSkeleton';

import galabApi from "../api/Galab.instance";
import {BASE_ORDER} from "../utils/Order";

class OrderDetailCertificates extends Component {
    constructor(props) {
        super(props);

        console.log(this.props.order.token);

        this.state = {
            order: (this.props.order) ? this.props.order : BASE_ORDER,
            showLoading: 'Lade Zertifikate',
            showLoadingLayout: false,
            certificates: [],
        };
    }

    componentDidMount() {
        this.setState({
            showLoadingLayout: true,
        });

        galabApi.get('file_uploads/' + this.props.order.token).then(response => {
            console.log(response);
            this.setState(prevState => ({
                //services: response.data['hydra:member'],
                //servicesFiltered: response.data['hydra:member'],
                certificates: response.data,
                showLoadingLayout: false,
            }))
        }).catch(error => {
            /*
            if (error.response.status === 401) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
            */
        });
    }

    render() {
        return (
            <div className="use-space scrollable">
                <div className="content-inner">
                    <h3 className="txtcenter">Zertifikatsübersicht</h3>
                    {this.state.showLoadingLayout !== false ?
                        <div>
                            <FileSkeleton/>
                            <FileSkeleton/>
                            <FileSkeleton/>
                            <FileSkeleton/>
                        </div>
                        :
                        <div>
                            {this.state.certificates.length > 0 ?
                                    this.state.certificates.map(({ id, ...otherOrderProps }) => (
                                        <File key={id} id={id} {...otherOrderProps} />
                                    ))
                                :
                                <div className="content-inner txtcenter">
                                    <h2 className="offset-bottom-1">Es wurden keine Zertifikate gefunden!</h2>
                                </div>
                            }
                            {/*
                            <hr/>
                            <h2 className="sticky-headline">Alle Zertifikate</h2>
                            <div className="files-list">
                                <File/>
                            </div>
                            */}
                        </div>
                    }
                </div>
            </div>
        );
    }
};

export default OrderDetailCertificates;