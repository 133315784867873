import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";

import HeaderNotification from "./HeaderNotification";
import HeaderUsermenu from "./HeaderUsermenu";

import Logo from "../assets/img/galab-logo-cropped.png";

class Header extends Component {
	render() {
		return (
			<div className="header-wrap">
				<div className="header">
					<div className="header-logo">
						<Link to="/index/orders">
							<img className="logo" src={Logo} />
						</Link>
					</div>
                    <nav className="header-navi">
                        <ul>
                            <li>
                                <NavLink to="/index/orders" onClick={this.removePanelOpenClass}>
                                    <i className="icon-lab"></i>Aufträge
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/index/certificates" onClick={this.removePanelOpenClass}>
                                    <i className="icon-paper"></i>Alle Zertifikate
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/index/services" onClick={this.removePanelOpenClass}>
                                    <i className="icon-grid"></i>Leistungen & Prüfpläne
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
					<div className="header-action">
                        <Link to="/index/orders/add" className="button">
							Auftrag anlegen
						</Link>
                        {/*
						<HeaderNotification />
                        */}
						<HeaderUsermenu />
					</div>
				</div>
			</div>
		)
	}
}

export default Header;