import React, { Component } from 'react';

import Tooltip from './Tooltip';

class ActionIcons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            coords: {},
            isOn: false
        };
    }

    mouseEnter = (event) => {
        const rect = event.target.getBoundingClientRect();

        this.setState({
            coords: {
                left: rect.x + rect.width / 2,
                top: rect.y + window.scrollY
            },
			isOn: true
        })
    }

    mouseLeave = (event) => {
        this.setState({isOn: false});
    }

    render() {
    	const { actionId, actionName, actionArticleNumber, readonly, viewHandle, editHandle, copyHandle, deleteHandle } = this.props;

		return (
			<div className="action-icons">
                {(viewHandle) ?
                    <Tooltip content="Auftragsdetails ansehen">
                        <i className="icon-action-view" onClick={() => viewHandle(actionId)}></i>
                    </Tooltip>
				: null}
                {readonly === false ?
                    <Tooltip content="Auftrag bearbeiten">
						<i className="icon-action-edit" onClick={() => editHandle(actionId)}></i>
                    </Tooltip>
                    :
					<Tooltip forceEnter={false} content={<div>Aufträge können nicht mehr bearbeitet werden sobald die Proben eingetroffen sind.<br/> <a href="mailto:info@galab.de">Zum Kundensupport</a></div>}>
                        <i className="icon-action-edit action-disabled"></i>
					</Tooltip>
                }
                <Tooltip content="Auftrag duplizieren">
                    <i className="icon-action-copy" onClick={() => copyHandle(actionId)}></i>
                </Tooltip>
                {readonly === false ?
                    <Tooltip content="Auftrag löschen">
                        <i className="icon-action-delete" onClick={() => deleteHandle(actionId, actionName, actionArticleNumber)}></i>
                    </Tooltip>
                    :
                    <Tooltip forceEnter={false} content={<div>Aufträge können nicht mehr bearbeitet werden sobald die Proben eingetroffen sind.<br/> <a href="mailto:info@galab.de">Zum Kundensupport</a></div>}>
                        <i className="icon-action-delete action-disabled"></i>
					</Tooltip>
                }
			</div>
		)
	}
}

export default ActionIcons;