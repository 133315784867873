import React, { Component } from 'react';
import { Route } from "react-router-dom";

import Header from "./Header";
import Breadcrumb from "./Breadcrumb";
import List from "../pages/List";
import CsvUpload from "../pages/CsvUpload";
import CreateOrder from "../pages/CreateOrder";
import Certificates from "../pages/Certificates";
import Services from "../pages/Services";
import TransferSheet from "../pages/TransferSheet";
	
class Layout extends Component {
	render() {
		return (
			<div className="main-wrap">
				<Header />
				<div className="content-wrap">
					<div className="container">
						<Route exact path="/index/orders">
							<Breadcrumb children={
								[
									{
										name: 'Auftragsübersicht'
									}
								]
							} />
							<div className="content">
								<List />
							</div>
						</Route>
                        <Route exact path="/index/orders/csv">
                            <CsvUpload />
                        </Route>
                        <Route exact path="/index/orders/add">
                            <CreateOrder />
                        </Route>
                        <Route path="/index/orders/add/transfersheet/:id" component={TransferSheet} />
						<Route exact path="/index/certificates">
							<Certificates />
						</Route>
						<Route exact path="/index/services">
							<Services />
						</Route>
					</div>
				</div>
			</div>
		)
	}
}

export default Layout;