import OrderActionTypes from './order.types';

// Fake data
import SERVICE_DATA from '../../data/service.data';

const INITIAL_STATE = {
    serviceData: SERVICE_DATA,
    serviceDataFiltered: SERVICE_DATA,
    order: {
        product_name: '',
        product_number: '',
        lot: '',
        best_before: '',
        number_of_samples: '',
        services: []
    }
};

const orderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OrderActionTypes.ADD_SERVICE_TO_ORDER:
            return {
                ...state,
                order: {
                    ...state.order,
                    services: [
                        ...state.order.services, action.payload
                    ]
                }
            }

        default:
            return state;
    }
};

export default orderReducer;