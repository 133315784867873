import React, { Component } from 'react';

import SERVICE_DATA from '../data/service.data';
import galabApi from "../api/Galab.instance";

class ServiceItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ... this.props
        };
    }

    changeFavorite = () => {
        let isFav = (this.state.weight > 0) ? true : false;

        if(isFav === false) {
            galabApi.post('analysis_service_weights',
                {
                    analysisServiceId: this.state.id
                }
            ).then(response => {
                this.setState(prevState => ({
                    weight: response.data.weight,
                    weightId: response.data.id
                }));
            }).catch(error => {});
        } else {
            galabApi.delete('analysis_service_weights/' + this.state.weightId).then(response => {
                this.setState(prevState => ({
                    weight: 0,
                    weightId: 0
                }));
            }).catch(error => {});
        }
/*
        this.setState({
            favourite: !isFav
        });
        */
    };

    render() {
        const { id, serviceId, name, weight, onClickHandle, onClickHandleType, showFavourite } = this.state;

        return (
            <div className="service-item">
                {(onClickHandle) ?
                <span className="action" onClick={() => onClickHandle(onClickHandleType, id, name, serviceId)}>
                    {(onClickHandleType == 'add') ?
                        <i className="icon-plus"></i> : <i className="icon-minus"></i> }

                </span> : null}
                <div className="service-item-content">
                    <strong title={name}>{name}</strong>
                    {serviceId}
                </div>
                {onClickHandleType == 'add' || showFavourite == true ?
                    (weight > 0) ?
                        <div className="favourite" onClick={this.changeFavorite}>
                            <i className="icon-favorite"></i>
                        </div>
                    :
                        <div className="favourite inactive" onClick={this.changeFavorite}>
                            <i className="icon-favorite"></i>
                        </div>

                : null
                }
            </div>
        )
    }
}


export default ServiceItem;