const SERVICE_DATA = [
    {
        id: 100850,
        name: 'Acrylamid',
        favourite: false
    },
    {
        id: 103905,
        name: 'Aflatoxin M1',
        favourite: false
    },
    {
        id: 103983,
        name: 'flatoxine (B1, B2, G1, G2)',
        favourite: false
    },
    {
        id: 102757,
        name: 'Alkylphenole, Bisphenole & Alkylphenolethoxylate',
        favourite: true
    },
    {
        id: 105024,
        name: 'Allergen Cashew',
        favourite: false
    },
    {
        id: 105125,
        name: 'Allergen Ei',
        favourite: false
    },
    {
        id: 101986,
        name: 'Allergen Erdnuss',
        favourite: false
    },
    {
        id: 101184,
        name: 'Allergen Gluten',
        favourite: false
    },
    {
        id: 101988,
        name: 'Allergen Haselnuss',
        favourite: false
    },
    {
        id: 106230,
        name: 'Allergen Lactose',
        favourite: false
    },
    {
        id: 101989,
        name: 'Allergen Lupine',
        favourite: false
    },
    {
        id: 105207,
        name: 'Allergen Mandel',
        favourite: false
    },
    {
        id: 105747,
        name: 'Allergen Milchprotein (Screening)',
        favourite: false
    },
    {
        id: 101990,
        name: 'Allergen Sellerie',
        favourite: false
    },
    {
        id: 105208,
        name: 'Allergen Senf',
        favourite: false
    },
    {
        id: 103912,
        name: 'Allergen Sesam',
        favourite: false
    },
    {
        id: 101991,
        name: 'Allergen Soja',
        favourite: false
    },
    {
        id: 105432,
        name: 'Alternaria-Toxine',
        favourite: false
    },
    {
        id: 100399,
        name: 'Aluminium (Al)',
        favourite: false
    },
    {
        id: 105405,
        name: 'Anorganisches Arsen (DIN EN 16802)',
        favourite: false
    },
    {
        id: 100417,
        name: 'Arsen (As)',
        favourite: false
    },
    {
        id: 106049,
        name: 'Erucasäure (C22:1 - DiätV)',
        favourite: false
    },
    {
        id: 100544,
        name: 'Blei (Pb)',
        favourite: false
    },
    {
        id: 100405,
        name: 'Cadmium (Cd)',
        favourite: false
    },
    {
        id: 100402,
        name: 'Cd/Hg/Pb inkl. Aufschluss',
        favourite: false
    },
    {
        id: 102344,
        name: 'Cd/Hg/Pb/As inkl. Aufschluss',
        favourite: false
    },
    {
        id: 100955,
        name: 'Chlormequat und Mepiquat',
        favourite: false
    },
    {
        id: 104083,
        name: 'Deoxynivalenol',
        favourite: false
    },
    {
        id: 104143,
        name: 'Dioxine, Furane und WHO PCB',
        favourite: false
    },
    {
        id: 100152,
        name: 'Dithiocarbamate',
        favourite: false
    },
    {
        id: 100178,
        name: 'Ethephon',
        favourite: false
    },
    {
        id: 103536,
        name: 'Ethoxyquin und Ethoxyquin-dimer',
        favourite: false
    },
    {
        id: 103581,
        name: 'Fosetyl und Phosphonsäure',
        favourite: false
    },
    {
        id: 104080,
        name: 'Fumonisine (B1, B2)',
        favourite: false
    },
    {
        id: 100193,
        name: 'Furan',
        favourite: false
    },
    {
        id: 104251,
        name: 'Fusarientoxine HT-2, T-2',
        favourite: false
    },
    {
        id: 102484,
        name: 'GALAB Pestizide 500PLUS®',
        favourite: false
    },
    {
        id: 100932,
        name: 'Gesamtbromid',
        favourite: false
    },
    {
        id: 104871,
        name: 'Glyphosat, AMPA, Glufosinat',
        favourite: false
    },
    {
        id: 106737,
        name: 'Maleinsäurehydrazid',
        favourite: false
    },
    {
        id: 106669,
        name: 'Matrin und Oxymatrin',
        favourite: false
    },
    {
        id: 100393,
        name: 'Methylquecksilber',
        favourite: false
    },
    {
        id: 105483,
        name: 'Mikrobiologie Paket',
        favourite: false
    },
    {
        id: 105770,
        name: 'Mikrobiologie Paket II',
        favourite: false
    },
    {
        id: 105778,
        name: 'Mikrobiologie Paket III',
        favourite: false
    },
    {
        id: 104282,
        name: 'Mineralöl, MOSH, MOAH, POSH',
        favourite: false
    },
    {
        id: 101230,
        name: 'Multimethode Mykotoxine',
        favourite: false
    },
    {
        id: 104092,
        name: 'Multimethode neutrale Fusarientoxine',
        favourite: false
    },
    {
        id: 100565,
        name: 'Natrium (Na)',
        favourite: false
    },
    {
        id: 104165,
        name: 'ndl-PCB',
        favourite: false
    },
    {
        id: 100541,
        name: 'Nickel (Ni)',
        favourite: false
    },
    {
        id: 106497,
        name: 'Nikotin',
        favourite: false
    },
    {
        id: 105895,
        name: 'Nitrat BG 1 mg/kg',
        favourite: false
    },
    {
        id: 103986,
        name: 'Ochratoxin A',
        favourite: false
    },
    {
        id: 106284,
        name: 'Oxalsäure',
        favourite: false
    },
    {
        id: 100270,
        name: 'PAK EC/EPA',
        favourite: false
    },
    {
        id: 106712,
        name: 'Paraquat und Diquat',
        favourite: false
    },
    {
        id: 101250,
        name: 'Patulin',
        favourite: false
    },
    {
        id: 105048,
        name: 'Perchlorat und Chlorat',
        favourite: false
    },
    {
        id: 106739,
        name: 'Phosphin',
        favourite: false
    },
    {
        id: 103398,
        name: 'QAV - Desinfektionsmittelrückstände',
        favourite: false
    },
    {
        id: 100411,
        name: 'Quecksilber (Hg)',
        favourite: false
    },
    {
        id: 100949,
        name: 'Sulfit',
        favourite: false
    },
    {
        id: 106193,
        name: 'Trimethylsulfonium (Trimesium)',
        favourite: false
    },
    {
        id: 104832,
        name: 'Tropan Alkaloide',
        favourite: false
    },
    {
        id: 100942,
        name: 'Weichmacher, Multimethode',
        favourite: false
    },
    {
        id: 104089,
        name: 'Zearalenon',
        favourite: false
    },
    {
        id: 100370,
        name: 'Zinnorganische Verbindungen',
        favourite: false
    }
];

export default SERVICE_DATA;
