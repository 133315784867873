import React, { Component } from 'react';

import LoginForm from "../components/LoginForm";

import Logo from "../assets/img/galab-logo.png";
import HomeLaptop from "../assets/img/galab-home-laptop.png";
import Background from "../assets/img/home-bg.svg";
	
class Home extends Component {
	render() {
        let today = new Date();

		return (
			<div className="home">
				<div className="left">
					<div className="inner">
						<img className="logo" src={Logo} />
                        <LoginForm />
					</div>
					<div className="footer">
						<div className="footer-bottom">
							© GALAB {today.getFullYear()} | <a href="https://www.galab.de/de/impressum.php" target="_blank">Imprint</a> | <a href="https://www.galab.de/de/unternehmen/pdf/Datenschutzerklaerung.pdf" target="_blank">Datenschutzerklärung</a>
						</div>
					</div>
				</div>
				<div className="right" style={{backgroundImage: "url(" + Background + ")"}}>
					<div className="right-footer txtcenter">
                        	<img src={HomeLaptop} />
						<div className="h1">Lege deine Aufträge bequem an</div>
						<p>Donec ullamcorper nulla non metus auctor fringilla. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibu.</p>
					</div>
				</div>
			</div>
		)
	}
}

export default Home;