import React, { Component } from 'react';

class Limit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.limit
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.limit
        });
    }

    handleChange = (event) => {
        this.props.limitHandle(event.target.value);
    };

    render() {
        let selectOptions = [30, 60, 90, 150];

        return (
            <div className="txtcenter offset-top-half">
                <div className="ui-select small">
                    <select onChange={this.handleChange} value={this.state.value}>
                        {
                            selectOptions.map((value) => (
                                <option key={value} value={value}>{value} Aufträge pro Seite</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        )
    }
}

export default Limit;