import React, { Component } from 'react';
import { Link } from "react-router-dom";

import File from '../components/File';
import OrderPreview from '../components/OrderPreview';
import ActionIcons from "./ActionIcons";
import OrderDetailMetaData from "./OrderDetailMetaData";
import OrderDetailCertificates from "./OrderDetailCertificates";
import OrderDetailCommunication from "./OrderDetailCommunication";
import galabApi from "../api/Galab.instance";

import { BASE_ORDER, getOrderById } from '../utils/Order';

class OrderDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: (this.props.order) ? this.props.order : BASE_ORDER,
            activeTabIndex: 0,
        };
    }

    componentDidMount() {
        if(
            typeof this.props.orderId !== 'undefined' &&
            this.props.orderId !== null &&
            typeof this.state.id === 'undefined'
        ) {
            getOrderById(this.props.orderId)
                .then(function(response) {
                    this.setState(prevState => ({
                        order: {
                            ...prevState.order,
                            ...response.data
                        }
                    }))
                }.bind(this))
                .catch(error => console.error('Outside error:', error));
        }
    }

    changeTabItem = (index) => {
        this.setState({ activeTabIndex: index });
    };

    getActiveClass = (index) => {
        if(this.state.activeTabIndex === index) {
            return 'active';
        } else {
            return '';
        }
    }

    render() {
        const { order } = this.state;

        return (
            <div>
                {order !== null ?
                    <div className="flex-grid-container flex-row">
                        <div className="order-detail">
                            <div className="order-detail-left">
                                <div className="content-inner order-detail-head">
                                    <strong>“{order.name}”</strong>
                                    <div className="order-detail-head-action">
                                        <ActionIcons
                                            actionId={order.id}
                                            actionName={order.name}
                                            actionArticleNumber={order.articleNumber}
                                            readonly={order.readonly}
                                            editHandle={this.props.editHandle}
                                            copyHandle={this.props.copyHandle}
                                            deleteHandle={this.props.deleteHandle}
                                        />
                                    </div>
                                </div>
                                <div className="order-detail-content use-space">
                                    <ul className="tab-menu">
                                        <li className={this.getActiveClass(0)} onClick={() => this.changeTabItem(0)}><i className="icon-order-detail"></i> Auftragsdetails</li>
                                        {/*
                                        <li className={this.getActiveClass(2)} onClick={() => this.changeTabItem(2)}><i className="icon-order-communication"></i> Kommunikation</li>
                                        */}
                                        <li className={this.getActiveClass(1)} onClick={() => this.changeTabItem(1)}><i className="icon-paper"></i> Zertifikate</li>
                                    </ul>
                                </div>
                                {(order.state === 'created') ?
                                    <div className="content-inner order-detail-footer">
                                        <Link to={'/index/orders/add/transfersheet/'+order.id} className="button button-block button-ghost">
                                            Begleitschein downloaden
                                        </Link>
                                    </div>
                                : null}
                            </div>
                            <div className="order-detail-right">
                                {(() => {
                                    switch (this.state.activeTabIndex) {
                                        case 1:
                                            return <OrderDetailCertificates order={order} />;
                                        case 2:
                                            return <OrderDetailCommunication />;
                                        default:
                                            return <OrderDetailMetaData
                                                order={order}
                                                editHandle={this.props.editHandle}
                                            />;
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                : null }
            </div>
        )
    }
}

export default OrderDetail;