import React from 'react';

import Spinner from '../components/Spinner';

const FullScreenLoader = ({ msg }) => (
    <div className="full-screen-loader">
        <div className="inner">
            <Spinner /> {msg} ...
        </div>
    </div>
);

export default FullScreenLoader;