import React from 'react';

import MessageStream from './MessageStream';

const OrderDetailCommunication = ({ order }) => (
    <div>
        <div className="use-space parent scrollable-parent scrollable-height-100">
            <div className="content-inner collapse-bottom-half txtcenter">
                <div className="avatar avatar-45">
                    <img src="https://placehold.it/45x45" />
                </div>
                <h3>Galab Kundensupport</h3>
            </div>
            <div className="content-inner collapse-top use-space scrollable">
                <MessageStream />
            </div>
            <div className="message-stream-footer content-inner">
                <textarea></textarea>
                <div>
                    <div className="message-stream-attachment">
                        <i className="icon-attachment"></i> Anhang
                    </div>
                    <button className="button fl-right">Senden</button>
                </div>
            </div>
        </div>
    </div>
);

export default OrderDetailCommunication;