import React from 'react';

import DocumentSvg from "../assets/img/document-skeleton.svg";

const FileSkeleton = () => {
    let skeletonWidth = (Math.random() > 0.5) ? 180 : 150;

    return (
        <div className="file">
            <img className="svg" src={DocumentSvg}/>
            <div className="file-content">
                <div className="name"><div className="skeleton-item" style={{width: skeletonWidth + 'px'}}></div></div>
                <div className="date"><div className="skeleton-item" style={{width: '50px'}}></div></div>
            </div>
        </div>
    )
};

export default FileSkeleton;