import React, { Component } from 'react';

class Infobox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            msg: this.props.msg,
            class: this.props.type,
        };
    }

	render() {
		return (
			<div className={'infobox infobox-' + this.state.class}>
				{this.state.msg}
			</div>
		)
	}
}

export default Infobox;