import React from 'react';

import AuditPlanItemSkeleton from '../components/AuditPlanItemSkeleton';

const AuditPlanListSkeleton = () => {
    return (
        <div className="audit-plan-list use-space parent scrollable">
            <div className="audit-plan-list-inner use-space">
                <AuditPlanItemSkeleton />
                <AuditPlanItemSkeleton />
                <AuditPlanItemSkeleton />
            </div>
        </div>
    )
}

export default AuditPlanListSkeleton;