import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import Breadcrumb from '../components/Breadcrumb';

import {BASE_ORDER, getOrderById} from '../utils/Order';

import TransfersheetCtaboxBgSvg from "../assets/img/transfersheet-ctabox-bg.svg";
import galabApi from "../api/Galab.instance";

class TransferSheet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: BASE_ORDER,
        };
	}

    componentDidMount() {
        const { match: { params } } = this.props;

        getOrderById(params.id)
            .then(function(response) {
            	this.setState({
                    order: {...response.data},
                });
            }.bind(this))
            .catch(function(error) {
                this.props.history.push('/index/orders');
            }.bind(this));
    }

    downloadPdf = (event) => {
        event.preventDefault();

    	if(this.state.order.token !== '') {
			const FileDownload = require("js-file-download");

            galabApi.get('delivery_notes/'+this.state.order.token, {
				responseType: 'blob'
			}).then(response => {
				FileDownload(response.data, 'probenbegleitschein_'+this.state.order.articleNumber+'.pdf');
            }).catch(error => {
                /*
                if (error.response.status === 401) setError(error.response.data.message);
                else setError("Something went wrong. Please try again later.");
                */
            });
		} else {
    		alert('Missing Token');
		}
	};

	render() {
		return (
			<div>
                <div className="fl-right back-btn-wrap">
                    <Link to="/index/orders" className="button button-hightlight collapse-left">
                        <i className="icon-chevron-left"></i> Zurück zur Auftragsübersicht
                    </Link>
                </div>
				<Breadcrumb children={
					[
						{
							name: 'Auftragsübersicht',
							to: '/index/orders'
						},
                        {
                            name: 'Auftrag anlegen',
                            to: '/index/orders/add'
                        },
                        {
                            name: 'Probengegleitschein'
                        }
					]
				} />
                <div className="transfersheet-page max-height-content-container">
                    <div className="flex-grid-container border-radius">
                        <div className="w-100 content-inner scrollable-parent">
								<div className="content-inner">
									<div className="txtcenter head">
										<span className="primary-color-highlight">
											Vielen Dank
										</span>
										<h2>Dein Auftrag wurde erfolgreich erstellt und an Galab übermittelt.</h2>
									</div>
									<div className="ctabox">
										<div className="content-inner">
											<h3>
												<small>Hinweis</small>
                                                Bitte Probenbegleitschein ausdrucken und dem Paket beilegen.
											</h3>
											<div className="transfersheet-form-wrap">
												<a href="#" onClick={this.downloadPdf} className="button button-white">
													Begleitschein downloaden
													<i className="icon-download"></i>
												</a>
												{/*
												<form className="forward-form">
													<span>An:</span>
													<button type="submit">
														<i className="icon-pen"></i>
													</button>
													<input id="special-input" placeholder="E-Mail Adresse" required />
													<label htmlFor="special-input">
														Begleitschein weiterleiten
														<i className="icon-action-send"></i>
													</label>
												</form>
												*/}
											</div>
											<img className="svg" src={TransfersheetCtaboxBgSvg} />
										</div>
									</div>
								</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default TransferSheet;