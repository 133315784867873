import React, { Component } from 'react';
import Tooltip from './Tooltip';

import {getStatusClassByState, getStatusTitleByState} from '../utils/Order';

class Status extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: getStatusTitleByState(this.props.state),
            type: this.props.state,
            class: getStatusClassByState(this.props.state),
        };
    }

	render() {
		return (
			<span className={'status status-' + this.state.class}>
				{this.state.title}
				{this.state.type === 'incomplete' &&
                    <Tooltip content="Bitte vervollständigen Sie Ihren Auftrag.">
                        <i className="icon-information-outline"></i>
                    </Tooltip>
				}
                {this.state.type === 'created' &&
                    <Tooltip content="Ihr Auftrag wird an unser System übertragen. Das kann bis zu 15 Minuten dauern.">
                        <i className="icon-loop"></i>
                    </Tooltip>
                }
                {this.state.type === 'synced' &&
                    <Tooltip content="Ihr Auftrag wurde erfolgreich an unser System übertragen.">
                        <i className="icon-synced"></i>
                    </Tooltip>
                }
			</span>
		)
	}
}

export default Status;