import React, { Component } from 'react';

class Avatar extends Component {
	render() {
		return (
			<div className="avatar">
				<img src="https://placehold.it/200x200" />
			</div>
		)
	}
}

export default Avatar;