import React, { Component } from 'react';

class AuditDetailForm extends Component {
    render() {
        let audit = this.props.audit;

        return (
            <form>
                <div className="form-row">
                    <div className="job-desc-wrap">
                        <label htmlFor="product_name">
                            <i className="icon-pen"></i>
                        </label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            onChange={this.props.handleChange}
                            value={audit.name}
                            placeholder="Prüfplanbezeichnung"
                        />
                        <i className="icon-information-outline"></i>
                    </div>
                </div>
            </form>
        )
    }
}

export default AuditDetailForm;