import React, { Component } from 'react';
import { Link } from "react-router-dom";

import AuditPlanItem from '../components/AuditPlanItem';

class AuditPlanList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { items } = this.props;

        return (
            <div className="audit-plan-list use-space parent scrollable">
                <div className="audit-plan-list-inner use-space">
                    {items.length > 0 ? items.map(({ id, ...otherServiceProps }) => (
                        <AuditPlanItem key={id} id={id} {...otherServiceProps} editHandle={this.props.editHandle} copyHandle={this.props.copyHandle} deleteHandle={this.props.deleteHandle} onClickHandle={this.props.clickHanlde} />
                    )) :
                        <div className="content-inner txtcenter">
                            <div className="offset-bottom-1">
                                <strong>Es wurden keine Prüfpläne gefunden!</strong>
                            </div>
                            <Link to="/index/services" className="button">
                                Neuen Prüfplan anlegen
                            </Link>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AuditPlanList;