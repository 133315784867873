import React from 'react';

const AuditPlanItemSkeleton = () => {
    return (
        <div className="audit-plan">
            <div className="audit-plan-head">
                <div className="skeleton-item" style={{width: '60px'}}></div>
            </div>
        </div>
    )
}

export default AuditPlanItemSkeleton;