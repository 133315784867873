import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import Home from "./pages/Home";
import Layout from "./components/Layout";
import Backend from "./components/Backend";

import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';

class App extends Component {

    render() {
		return (
			<Router>
				<div className="app">
                    <PrivateRoute path="/index" component={Layout} />
                    <PrivateRoute path="/backend" component={Backend} />
                    <PublicRoute exact path="/" component={Home} />
				</div>
				<ToastContainer autoClose={3000} />
			</Router>
		);
	}
}

export default App;