import React, { Component } from 'react';

class Paging extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.props.paging
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps.paging
        });
    }

    clickEvent = (event, url) => {
        event.preventDefault();

        this.props.pagingHandle(url);
    };

    render() {
        return (
            <div className="paging txtcenter">
                {this.state['hydra:previous'] !== null && this.state['hydra:first'] !== null ?
                    <a className="first" href={this.state['hydra:first']} onClick={(e) => {
                        this.clickEvent(e, this.state['hydra:first'])
                    }}>
                        <i className="icon-chevron-left"></i><i className="icon-chevron-left"></i> erste Seite
                    </a>
                :
                    <span className="first">
                        <i className="icon-chevron-left"></i><i className="icon-chevron-left"></i> erste Seite
                    </span>
                }

                {this.state['hydra:previous'] !== null ?
                    <a className="prev" href={this.state['hydra:previous']} onClick={(e) => {
                        this.clickEvent(e, this.state['hydra:previous'])
                    }}>
                        <i className="icon-chevron-left"></i> zurück
                    </a>
                :
                    <span className="prev">
                        <i className="icon-chevron-left"></i> zurück
                    </span>
                }

                {/*
                <a className="page" href="#">1</a>
                <a className="page" href="#">2</a>
                <span className="page current">3</span>
                <a className="page" href="#">4</a>
                <a className="page" href="#">5</a>
                */}

                <span className="page current">{this.props.page}</span>

                {this.state['hydra:next'] !== null ?
                    <a className="next" href={this.state['hydra:next']} onClick={(e) => {
                        this.clickEvent(e, this.state['hydra:next'])
                    }}>
                        weiter <i className="icon-chevron-right"></i>
                    </a>
                :
                    <span className="next">
                        weiter <i className="icon-chevron-right"></i>
                    </span>
                }

                {this.state['hydra:next'] !== null && this.state['hydra:last'] !== null ?
                    <a className="last" href={this.state['hydra:last']} onClick={(e) => {
                        this.clickEvent(e, this.state['hydra:last'])
                    }}>
                        letzte seite <i className="icon-chevron-right"></i><i className="icon-chevron-right"></i>
                    </a>
                :
                    <span className="last">
                        letzte seite <i className="icon-chevron-right"></i><i className="icon-chevron-right"></i>
                    </span>
                }
            </div>
        )
    }
}

export default Paging;