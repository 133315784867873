import React, { Component } from 'react';

import OrderMetaData from '../components/OrderMetaData';
import ServiceList from '../components/ServiceList';

class OrderPreview extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { order } = this.props;

        return (
            <div className="use-space scrollable">
                <div className="content-inner">
                    <h2 className="txtcenter">Zusammenfassung</h2>
                    <div className="infobox txtcenter">
                        <strong>“{order.name}”</strong>
                    </div>
                    <OrderMetaData order={order} />
                    <strong>Leistungen ({order.analysisServiceData.length})</strong>
                </div>
                <div style={{minHeight: '60px'}}>
                    <ServiceList
                        items={order.analysisServiceData}
                        selectedItems={[]}
                        handleList={this.props.handleList}
                        handleListType='remove'
                    />
                </div>
            </div>
        )
    }
}

export default OrderPreview;