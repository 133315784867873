import React, { Component, useEffect } from "react";
import Portal from './Portal';
import TooltipChild from './TooltipChild';
// import debounce from "lodash/debounce";

class Tooltip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            coords: {},
            isOn: false
        };
    }

    mouseEnter = (event) => {
        // setTimeout(() => {
            const rect = event.target.getBoundingClientRect();

            this.setState({
                coords: {
                    left: rect.x + rect.width / 2,
                    top: rect.y + window.scrollY,
                    visibility: 'visible'
                },
                isOn: true
            });
        // }, 100);
    };

    mouseLeave = (event) => {
        this.setState({
            coords: {},
            isOn: false
        });
    };

    render() {
        return (
            <div className="fancy-tooltip-wrap" onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                {this.props.children}
                {
                    this.state.isOn &&
                    <Portal>
                        <TooltipChild forceEnter={this.props.forceEnter} coords={this.state.coords}>
                            {this.props.content}
                        </TooltipChild>
                    </Portal>
                }
            </div>
        );
    }
};

export default Tooltip;
