import React, { Component } from 'react';

import ServiceItem from '../components/ServiceItem';

class ServiceList extends Component {
    render() {
        const { items, selectedItems } = this.props;

        return (
            <div className="service-list use-space parent scrollable">
                <div className="service-list-inner use-space">
                    {items ? items.map(({ id, ...otherServiceProps }) => (
                        (!selectedItems.some(item => item.id === id)) ? <ServiceItem key={id} id={id} {...otherServiceProps} onClickHandle={this.props.handleList} onClickHandleType={this.props.handleListType} showFavourite={this.props.showFavourite} /> : null
                    )) : null}
                </div>
            </div>
        )
    }
}

export default ServiceList;