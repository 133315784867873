import React, { Component } from 'react';
import {toast} from 'react-toastify';
import {
    withRouter
} from 'react-router-dom';

import Breadcrumb from '../components/Breadcrumb';
import FullScreenLoader from '../components/FullScreenLoader';

import CsvDownloadSvg from "../assets/img/csv-download.svg";
import CsvFillSvg from "../assets/img/csv-fill.svg";
import CsvUploadSvg from "../assets/img/csv-upload.svg";

import galabApi from "../api/Galab.instance";

class CsvUpload extends Component {
    constructor(props) {
        super(props);

        this.state ={
            showLoading: false,
            file: null
        };

        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
    }

    onFormSubmit(e){
        e.preventDefault(); // Stop form submit

        this.fileUpload();
    }

    onChange(e) {
        this.setState({
            file: e.target.files[0],
            showLoading: true
        });

        setTimeout(this.fileUpload, 500);
    }

    fileUpload(){
        let formData = new FormData();
        formData.append('file',this.state.file);

        galabApi.post('csv_order_request_uploads',
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
        ).then(response => {
            if(response.status === 200) {
                this.props.history.push('/index/orders');

                let csvUploadMessage = 'Es wurde ' + response.data.importedOrders + ' Probe importiert.';
                if(response.data.importedOrders > 1) {
                    csvUploadMessage = 'Es wurden ' + response.data.importedOrders + ' Proben importiert.';
                }

                toast.success(csvUploadMessage);
            }
        }).catch(error => {

        });
    }

    render() {
        return (
            <div>
                <Breadcrumb children={
                    [
                        {
                            name: 'Auftragsübersicht',
                            to: '/index/orders'
                        },
                        {
                            name: 'CSV Upload'
                        }
                    ]
                } />
                <div className="csv-upload">
                    <div className="flex-grid-container">
                        <div className="content-inner use-space parent">
                            <div className="flex-grid-container inner-border">
                                <div className="w-33">
                                    <div className="content-inner txtcenter">
                                        <div className="number">1.</div>
                                        <img className="svg" src={CsvDownloadSvg} />
                                        <h2 className="has-subline">Datei downloaden</h2>
                                        <p>
                                            Laden Sie sich unsere vordefinierte CSV Datei auf ihren Computer runter.
                                        </p>
                                        <a href="https://galab.api.staging-env.de/build/misc/example.csv" className="button">
                                            CSV Datei downloaden
                                        </a>
                                    </div>
                                </div>
                                <div className="w-33">
                                    <div className="content-inner txtcenter">
                                        <div className="number">2.</div>
                                        <img className="svg" src={CsvFillSvg} />
                                        <h2 className="has-subline">Datei ausfüllen</h2>
                                        <p>
                                            Füllen Sie die Spalten der Tabllelle mit ihren produktspezifischen Angaben aus und speichern Sie bitte die Datei als CSV.  Falls Sie schwierigkeiten haben sollten, finden Sie hier eine <a href="#">Lösung</a>.
                                        </p>
                                    </div>
                                </div>
                                <div className="w-33">
                                    <div className="content-inner txtcenter">
                                        <div className="number">3.</div>
                                        <img className="svg" src={CsvUploadSvg} />
                                        <h2 className="has-subline">Datei hochladen</h2>
                                        <p>
                                            Laden Sie die CSV Datei wieder hoch und kehren Sie zur Auftragsübersicht zurück.
                                        </p>
                                        <form onSubmit={this.onFormSubmit}>
                                            <input id="csvUpload" type="file" accept=".csv" onChange={this.onChange} style={{display:'none'}} />
                                            <label htmlFor="csvUpload" className="button">
                                                CSV Datei uploaden
                                            </label>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showLoading === true ?
                        <FullScreenLoader msg="CSV wird hochgeladen" />
                    : null
                }
            </div>
        )
    }
}

export default withRouter(CsvUpload);