import React from 'react';

const TextareaInput = ({ handleChange, label, customfieldid, ...otherProps }) => (
    <div className="form-row">
        {label ? (
            <label htmlFor={otherProps.id}>
                {label}
            </label>
        ) : null}
        <textarea data-customfield={customfieldid} className="form-input" onChange={handleChange} {...otherProps} />
    </div>
);

export default TextareaInput;