import React, { Component } from 'react';

import Tooltip from './Tooltip';

class AuditPlanItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contentOpen: false,
        };
    }

    handleContentOpen = ()=>{
        let setState = !this.state.contentOpen;

        this.setState({
            contentOpen: setState
        })
    };

    render() {
        const { id, name, onClickHandle, editHandle, copyHandle, deleteHandle, services } = this.props;
        const { contentOpen } = this.state;

        return (
            <div className="audit-plan">
                <div className="audit-plan-head">
                    {(onClickHandle) ? <span className="action" onClick={() => onClickHandle(services)}>
                        <i className="icon-plus"></i>
                    </span> : null}
                    {name}
                    {(editHandle) ?
                        <Tooltip content="Prüfplan bearbeiten">
                            <div className="edit-trigger" onClick={() => editHandle(id)}>
                                <i className="icon-action-edit"></i>
                            </div>
                        </Tooltip>
                    : null}
                    {(copyHandle) ?
                        <Tooltip content="Prüfplan kopieren">
                            <div className="edit-trigger" onClick={() => copyHandle(id)}>
                                <i className="icon-action-copy"></i>
                            </div>
                        </Tooltip>
                    : null}
                    {(deleteHandle) ?
                        <Tooltip content="Prüfplan löschen">
                            <div className="edit-trigger" onClick={() => deleteHandle(id)}>
                                <i className="icon-action-delete"></i>
                            </div>
                        </Tooltip>
                    : null}
                    {contentOpen === true ?
                        <i onClick={this.handleContentOpen} className="open-trigger icon-chevron-up"></i>
                    :
                        <i onClick={this.handleContentOpen} className="open-trigger icon-chevron-down"></i>
                    }
                </div>
                {contentOpen === true ?
                    <div className="audit-plan-content">
                        <ul>
                            {services ? services.map(({ id, name }) => (
                                <li>{name}</li>
                            )) : null}
                        </ul>
                    </div>
                : null }
            </div>
        )
    }
}

export default AuditPlanItem;