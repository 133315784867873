import React from 'react';

const Modal = (props) => (
    <div className="modal">
        <div className="modal-inner">
            {props.children}
            <div className="modal-close" onClick={() => props.closeHandle(null)}>
                <i className="icon-action-close"></i>
                <small>schließen</small>
            </div>
        </div>
        <div className="modal-overlay" onClick={() => props.closeHandle(null)}></div>
    </div>
);

export default Modal;